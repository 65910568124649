import { Match } from 'src/common/types'
import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { getClubInfo } from 'src/common/clubs'
import { SettingsContext } from '../../settings/settings-context'
import AbbrevScore from 'src/features/common/abbrev-score'
import { rounded } from 'src/common/styles'

type ResultProps = {
  match: Match
}
const Result = ({ match }: ResultProps) => {
  const [{ isEuroMode }] = useContext(SettingsContext)
  const { result, homeTeam, awayTeam } = match
  const homeAbbrev = getClubInfo(homeTeam).abbrev
  const awayAbbrev = getClubInfo(awayTeam).abbrev

  if (!result) return null

  const { atlResult, homeResult, awayResult, homeScore, awayScore } = result

  return (
    <Container
      //@ts-ignore
      component={Link}
      variant="contained"
      color="primary"
      to={`/results/${match.date.split('T')[0]}`}
    >
      <WinLose didAtlWin={atlResult === 'w'} isTie={atlResult === 't'}>
        {atlResult === 'w'
          ? 'WIN'
          : atlResult === 'l'
          ? 'LOSS'
          : isEuroMode
          ? 'DRAW'
          : 'TIE'}
      </WinLose>

      <ResultText>
        <Score
          abbrev={homeAbbrev}
          score={homeScore}
          isWinner={homeResult === 'w'}
        />
        <Score
          abbrev={awayAbbrev}
          score={awayScore}
          isWinner={awayResult === 'w'}
          isLeft
        />
      </ResultText>

      <ViewText>view</ViewText>
    </Container>
  )
}

export default Result

const Container = styled(Button)`
  width: fit-content;
  max-width: 100%;
  grid-column: 1 / -1;
  text-align: center;
  font-size: 0.68rem;
  padding: 2px;
  @media (min-width: 375px) {
    font-size: 0.75rem;
    padding: 4px;
  }
  transition: ${(p) =>
    p.theme.transitions.create('background-color', {
      duration: p.theme.transitions.duration.shortest,
    })};
  &:hover {
    background-color: ${(p) => p.theme.palette.primary.light};
  }
  .MuiButton-label {
    display: grid;
    grid-template-columns: calc(4ch + 16px) 1fr;
    justify-items: center;
  }
`
type WinLoseProps = {
  didAtlWin: boolean
  isTie: boolean
}
const WinLose = styled.span<WinLoseProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1 / span 2;
  font-size: 1em;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 4px 0;
  color: ${(p) => p.theme.palette.primary.main};
  ${rounded}
  background-color: ${(p) =>
    p.didAtlWin
      ? p.theme.palette.secondary.main
      : p.isTie
      ? `${p.theme.palette.grey[800]}cc`
      : p.theme.palette.background.default};
`
const ResultText = styled(Typography)`
  color: rgba(255, 255, 255, 0.87);
  color: ${(p) => p.theme.palette.background.default};
  font-size: 1.3em;
  width: fit-content;
  display: flex;
`
const Score = styled(AbbrevScore)`
  margin: 0 10px;
  @media (min-width: 375px) {
    margin: 0 16px;
  }
`
const ViewText = styled(Typography)`
  font-size: 0.87em;
  color: ${(p) => p.theme.palette.background.default};
  text-transform: uppercase;
  width: fit-content;
`
