import React from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Hidden from '@material-ui/core/Hidden'
import { SetState } from 'src/common/types'
// import { TabValue } from 'src/pages/schedule'

type ScheduleTabsProps = {
  months: number[]
  monthTabValue: number
  allTabValue: number
  setMonthTabValue: SetState<number>
  setAllTabValue: SetState<number>
  // tabValue: TabValue
  // setTabValue: SetState<TabValue>
}
const ScheduleTabs = ({
  months,
  setMonthTabValue,
  setAllTabValue,
  monthTabValue,
  allTabValue,
}: ScheduleTabsProps) => {
  const handleMonthChange = (e: React.ChangeEvent<{}>, month: number) => {
    // setTabValue({ ...tabValue, month })
    setMonthTabValue(month)
  }
  const handleAllChange = (e: React.ChangeEvent<{}>, all: number) => {
    // setTabValue({ ...tabValue, all })
    setAllTabValue(all)
  }

  const monthTabComponents = months.map((month) => (
    <MonthTab key={month} label={monthAbbrevs[month]} value={month} />
  ))

  return (
    <>
      <Hidden mdUp>
        <Tabs
          value={monthTabValue}
          onChange={handleMonthChange}
          variant="scrollable"
          indicatorColor="primary"
        >
          {monthTabComponents}
        </Tabs>
      </Hidden>
      <Hidden smDown>
        <Tabs
          value={allTabValue}
          onChange={handleAllChange}
          variant="standard"
          indicatorColor="primary"
        >
          <Tab label="UPCOMING" value={0} />
          <Tab label="PREVIOUS" value={1} />
        </Tabs>
      </Hidden>
    </>
  )
}

export default ScheduleTabs

const MonthTab = styled(Tab)`
  min-width: 72px;
  width: 72px;
`

const monthAbbrevs = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
] as const
