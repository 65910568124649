import React from 'react'
import Img from 'gatsby-image'
import GenericLogo from 'src/common/generic-logo'
import useScheduleClubLogos from './use-fixed-club-logos'

type ScheduleClubLogoProps = {
  clubName: string
}
const ScheduleClubLogo = ({ clubName }: ScheduleClubLogoProps) => {
  const logoData = useScheduleClubLogos()

  const logoIndex = logoData.findIndex(
    (logo: any) =>
      logo.childImageSharp?.fixed?.originalName === `${clubName}.png`
  )

  return (
    <>
      {logoIndex >= 0 ? (
        <Img
          fixed={logoData[logoIndex].childImageSharp?.fixed as any}
          draggable={false}
          // fadeIn={false}
        />
      ) : (
        GenericLogo
      )}
    </>
  )
}

export default ScheduleClubLogo
