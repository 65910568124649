import { useStaticQuery, graphql } from 'gatsby'
import { SmallClubLogosQuery } from 'types/graphql-types'

const useScheduleClubLogos = () => {
  const { allFile } = useStaticQuery<SmallClubLogosQuery>(graphql`
    query SmallClubLogos {
      allFile(
        filter: {
          relativeDirectory: { eq: "logos" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fixed(width: 50, quality: 1) {
              originalName
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)

  const logoNodes = allFile.nodes

  return logoNodes
}

export default useScheduleClubLogos
