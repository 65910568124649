import { Match } from 'src/common/types'
import { HomeAwayFilter } from './types'

export const filterHomeAway = (
  matches: Match[],
  homeAwayFilter: HomeAwayFilter
) => {
  if (homeAwayFilter === 'H') {
    return matches.filter((match) => match.isHome)
  }
  if (homeAwayFilter === 'A') {
    return matches.filter((match) => !match.isHome)
  }
  return matches
}

export const checkHomeAway = (match: Match, homeAwayFilter: HomeAwayFilter) =>
  homeAwayFilter
    ? homeAwayFilter === 'H'
      ? match.isHome
      : !match.isHome
    : true

/**
 * Function to return relevant matches to map over for schedule
 * @param matches returned from useMatchData
 * @param homeAwayFilter filter prop
 * @param dateCheckComparisonValue value to compare match date to in dateCheckFunc
 * @param dateCheckFunc function to compare match date to targeted range
 */
export function getMatches<V>(
  matches: Match[],
  homeAwayFilter: HomeAwayFilter,
  dateCheckComparisonValue: V,
  dateCheckFunc: (matchDate: Date, comparisonValue: V) => boolean
) {
  return matches.filter((match) => {
    const isHomeAway = checkHomeAway(match, homeAwayFilter)
    const isInDateRange = dateCheckFunc(
      new Date(match.date),
      dateCheckComparisonValue
    )
    return isInDateRange && isHomeAway
  })
}
