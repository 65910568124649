/* eslint-disable no-lone-blocks */
import { WindowLocation } from '@reach/router'
import React, { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import color from 'color'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden'
import { PageProps } from 'gatsby'
import { LayoutContext } from 'src/features/layout'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import useMatchData from 'src/common/hooks/use-match-data'
import useNextMatchData from 'src/common/hooks/use-next-match-data'
import ScheduleTopBar from 'src/features/schedule/schedule-top-bar'
import SEO from 'src/common/seo'
import AllScheduleItems from 'src/features/schedule/all-schedule-items'
import AtlBgTwo from 'src/features/atl-bg-two'
import MonthScheduleItems from 'src/features/schedule/month-schedule-items'
import useSwipeNav from 'src/common/hooks/use-swipe-nav'
import ScheduleTabs from 'src/features/schedule/schedule-top-bar/schedule-tabs'
import ClubFilterSchedule from 'src/features/schedule/club-filter-schedule'

export type HomeAwayFilter = 'H' | 'A' | null

type SchedulePageProps = {
  location: WindowLocation & { state?: { date?: string } }
} & PageProps
const SchedulePage = ({ location }: SchedulePageProps) => {
  const props = useMotionNavOffsetStyle({}, { cssProp: 'padding' })
  const { setLocation } = useContext(LayoutContext)
  const nextMatch = useNextMatchData()
  const matches = useMatchData()
  const months = Array.from(
    new Set(
      matches.map((match) => {
        const date = new Date(match.date)
        return date.getMonth()
      })
    )
  )
  const monthIndex = months.findIndex(
    (month) => month === nextMatch.matchDate?.getMonth()
  )
  const defaultMonth = months[monthIndex > -1 ? monthIndex : months.length - 1]
  const [monthTabValue, setMonthTabValue] = useState(defaultMonth)
  const [allTabValue, setAllTabValue] = useState(
    nextMatch.matchDate === undefined ? 1 : 0
  )
  const [clubFilter, setClubFilter] = useState<string | null>(null)
  const [homeAwayFilter, setHomeAwayFilter] = useState<HomeAwayFilter>(null)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const monthHandlers = useSwipeNav(setMonthTabValue, months, isFilterOpen)
  const allHandlers = useSwipeNav(setAllTabValue, [0, 1], isFilterOpen)

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO title="Schedule" />
      <AtlBgTwo />
      <motion.div {...props}>
        {/* <ScheduleTopBar
          {...{
            months,
            clubFilter,
            setClubFilter,
            homeAwayFilter,
            setHomeAwayFilter,
            isFilterOpen,
            setIsFilterOpen,
          }}
        >
          <ScheduleTabs
            {...{
              months,
              monthTabValue,
              setMonthTabValue,
              allTabValue,
              setAllTabValue,
            }}
          />
        </ScheduleTopBar> */}

        <Hidden smDown>
          <ScheduleContainer {...allHandlers}>
            <SwipeContainer />
            <ScheduleTopBar
              {...{
                months,
                clubFilter,
                setClubFilter,
                homeAwayFilter,
                setHomeAwayFilter,
                isFilterOpen,
                setIsFilterOpen,
              }}
            >
              <ScheduleTabs
                {...{
                  months,
                  monthTabValue,
                  setMonthTabValue,
                  allTabValue,
                  setAllTabValue,
                }}
              />
            </ScheduleTopBar>
            {clubFilter ? (
              <ClubFilterSchedule clubFilter={clubFilter} />
            ) : (
              <AllScheduleItems
                tabValue={allTabValue}
                homeAwayFilter={homeAwayFilter}
              />
            )}
          </ScheduleContainer>
        </Hidden>
        <Hidden mdUp>
          <ScheduleContainer {...monthHandlers}>
            <ScheduleTopBar
              {...{
                months,
                clubFilter,
                setClubFilter,
                homeAwayFilter,
                setHomeAwayFilter,
                isFilterOpen,
                setIsFilterOpen,
              }}
            >
              <ScheduleTabs
                {...{
                  months,
                  monthTabValue,
                  setMonthTabValue,
                  allTabValue,
                  setAllTabValue,
                }}
              />
            </ScheduleTopBar>
            <SwipeContainer />
            {clubFilter ? (
              <ClubFilterSchedule clubFilter={clubFilter} />
            ) : (
              <MonthScheduleItems
                tabValue={monthTabValue}
                homeAwayFilter={homeAwayFilter}
              />
            )}
          </ScheduleContainer>
        </Hidden>
      </motion.div>
    </>
  )
}

export default SchedulePage

const SwipeContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const ScheduleContainer = styled.div`
  margin: auto;
  padding: 0;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  max-width: ${(p) => p.theme.breakpoints.values.md - 100}px;
  height: 100%;
  ${(p) => p.theme.breakpoints.up('lg')} {
    margin-bottom: 0;
  }
  .item-wrapper:nth-of-type(odd) {
    /* background-color: ${(p) => p.theme.palette.secondary.dark}; */
    background-color: ${(p) =>
      color(p.theme.palette.secondary.dark)
        .fade(0.2)
        .rgb()
        .toString()};
    .cutout-text {
      color: ${(p) => p.theme.palette.secondary.dark};
    }
  }
  .item-wrapper:nth-of-type(even) {
    background-color: ${(p) =>
      color(p.theme.palette.background.default)
        .fade(0.2)
        .rgb()
        .toString()};
    .cutout-text {
      color: ${(p) => p.theme.palette.background.default};
    }
  }
`
