import { Match } from 'src/common/types'
import React, { useContext } from 'react'
import dateFormat from 'dateformat'
import styled from 'styled-components'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ScheduleClubLogo from '../fixed-club-logo'
import { SettingsContext } from '../../settings/settings-context'
import { spacing } from 'src/features/standings/styles'
import Result from './result'
import { rounded, Card } from 'src/common/styles'

/*
  TODO:
  ---
    * perhaps split this up?
 */

type ScheduleItemProps = {
  match: Match
  isNextMatch?: boolean
}

const ScheduleItem = ({ match, isNextMatch }: ScheduleItemProps) => {
  const [{ isEuroMode }] = useContext(SettingsContext)

  const { result, date } = match

  const smallDateStr = isEuroMode ? 'ddd, "the" dS "of" mmm' : 'ddd, mmm dS'
  const longDateStr = isEuroMode ? 'dddd, "the" dS "of" mmmm' : 'dddd, mmmm dS'
  const timeStr = isEuroMode ? 'HH:MM Z' : 'h:MMTT Z'

  return (
    <Container className="item-wrapper">
      <LeftContainer>
        <LeftTopContainer>
          <ScheduleClubLogo clubName={match.opponent} />
          <TeamDateContainer>
            <div>
              <OpponentName variant="h6" className="opponent">
                {match.opponent}
              </OpponentName>
            </div>
            {isNextMatch && (
              <NextMatch>
                <NextMatchText className="cutout-text">
                  next {isEuroMode ? 'match' : 'game'}
                </NextMatchText>
              </NextMatch>
            )}
            {result && <Result {...{ result, date, match }} />}
            <div>
              <Hidden xsDown>
                <StyledDate variant="body1" gutterBottom>
                  {dateFormat(match.date, longDateStr)}
                </StyledDate>
              </Hidden>
              <Hidden smUp>
                <StyledDate variant="body1" gutterBottom>
                  {dateFormat(match.date, smallDateStr)}
                </StyledDate>
              </Hidden>
              <StyledTime>{dateFormat(match.date, timeStr)}</StyledTime>
            </div>
          </TeamDateContainer>
        </LeftTopContainer>
        <LeftBottomContainer>
          <Location variant="body2">{match.location}</Location>
          <Broadcast variant="subtitle2">{match.broadcast}</Broadcast>
        </LeftBottomContainer>
      </LeftContainer>

      <RightContainer>
        <TopOfBoxText color="primary" align="center">
          {match.competitionType}
        </TopOfBoxText>
        <HomeAway className="cutout-text">{match.isHome ? 'H' : 'A'}</HomeAway>
      </RightContainer>
    </Container>
  )
}

export default ScheduleItem

// const Container = styled(Paper)`
const Container = styled(Card)`
  display: grid;
  grid-template-columns: 1fr ${spacing(8)};
  grid-gap: ${spacing(1)};
  /* margin: ${spacing(1)} 1px; */
  & {
    /* margin-top: 0; */
    margin-top: ${spacing(1)};
  }
  /* padding: ${spacing(1)}; */
  & {
    padding-right: 0px;
  }
  background-color: ${(p) => p.theme.palette.background.paper}cc;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-gap: ${spacing(2)};
    padding: ${spacing(2)};
    padding-right: 0px;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${spacing(1)} 0;
  }
`
const LeftContainer = styled.div`
  grid-template-columns: 1fr;
  grid-gap: ${spacing(1)};
  display: grid;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-gap: ${spacing(2)};
    grid-template-columns: auto auto;
  }
`
const LeftTopContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 100%;
  ${(p) => p.theme.breakpoints.up('sm')} {
    width: auto;
  }
`
const NextMatch = styled.div`
  width: 12ch;
  text-align: center;
  background-color: ${(p) => p.theme.palette.primary.main};
  ${rounded};
`
const NextMatchText = styled(Typography)`
  opacity: 0.8;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 0.85em;
  letter-spacing: 0.15ch;
`
const TeamDateContainer = styled.div`
  display: grid;
  grid-row-gap: ${spacing(1)};
  padding: 0 ${spacing(1)};
  ${(p) => p.theme.breakpoints.up('sm')} {
    padding: 0 ${spacing(2)}px;
  }
`
const TopOfBoxText = styled(Typography)`
  line-height: 1.2;
`
const OpponentName = styled(TopOfBoxText)`
  line-height: 0.9;
  ${(p) => p.theme.breakpoints.up('sm')} {
    white-space: nowrap;
  }
`
const StyledDate = styled(Typography)`
  font-weight: bold;
  letter-spacing: 0.15ch;
  line-height: 0.9;
  font-size: 1rem;
  opacity: 0.75;
`
const StyledTime = styled(Typography)`
  letter-spacing: 0.15ch;
  font-size: 0.9rem;
  line-height: 0.85;
  opacity: 0.65;
`
const LeftBottomContainer = styled.div`
  text-align: right;
  opacity: 0.55;
  width: 70%;
  justify-self: end;
  ${(p) => p.theme.breakpoints.up('sm')} {
    padding-top: 0;
    width: 100%;
  }
`
const Location = styled(Typography)`
  font-size: 0.8rem;
  letter-spacing: 0.15ch;
  font-weight: bold;
`
const Broadcast = styled(Typography)`
  font-size: 0.75rem;
`
const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid ${(p) => p.theme.palette.primary.dark};
  width: 100%;
`
const circleSizePx = 32
const HomeAway = styled.div`
  width: ${circleSizePx}px;
  height: ${circleSizePx}px;
  border-radius: 99999px;
  font-size: 18px;
  line-height: ${circleSizePx}px;
  text-align: center;
  background-color: ${(p) => p.theme.palette.primary.dark};
  background-color: ${(p) => p.theme.palette.primary.main};
`
