import { HomeAwayFilter } from 'src/pages/schedule'
import React from 'react'
import { getMatches } from './utils'
import useMatchData from 'src/common/hooks/use-match-data'
import ScheduleItem from './schedule-item'

type MonthScheduleProps = {
  tabValue: number
  homeAwayFilter: HomeAwayFilter
}
const AllScheduleItems = ({ tabValue, homeAwayFilter }: MonthScheduleProps) => {
  const matches = useMatchData()
  const now = new Date()
  const checkPrev = (matchDate: Date, now: Date) => matchDate < now
  const previousMatches = getMatches<Date>(
    matches,
    homeAwayFilter,
    now,
    checkPrev
  ).reverse()

  const checkUpcoming = (matchDate: Date, now: Date) => matchDate >= now
  const upcomingMatches = getMatches(
    matches,
    homeAwayFilter,
    now,
    checkUpcoming
  )

  return tabValue === 1 ? (
    <>
      {previousMatches.map((match) => (
        <ScheduleItem key={match.id} match={match} />
      ))}
    </>
  ) : (
    <>
      {upcomingMatches.map((match) => (
        <ScheduleItem key={match.id} match={match} />
      ))}
    </>
  )
}

export default React.memo(AllScheduleItems)
