import { HomeAwayFilter } from 'src/pages/schedule'
import React from 'react'
import { getMatches } from './utils'
import Divider from '@material-ui/core/Divider'
import useMatchData from 'src/common/hooks/use-match-data'
import useNextMatchData from 'src/common/hooks/use-next-match-data'
import ScheduleItem from './schedule-item'

type MonthScheduleProps = {
  tabValue: number
  homeAwayFilter: HomeAwayFilter
}
const MonthScheduleItems = ({
  tabValue,
  homeAwayFilter,
}: MonthScheduleProps) => {
  const matches = useMatchData()
  const nextMatch = useNextMatchData()

  const checkInMonth = (matchDate: Date, month: number) =>
    matchDate.getMonth() === month
  const monthMatches = getMatches(
    matches,
    homeAwayFilter,
    tabValue,
    checkInMonth
  )

  const isNextMatchLastInMonth =
    nextMatch.matchDate?.toISOString() ===
    monthMatches[monthMatches.length - 1]?.date

  const getNextMonthComponents = () => {
    const nextMonthMatches = getMatches(
      matches,
      homeAwayFilter,
      tabValue + 1,
      checkInMonth
    )
    return nextMonthMatches
      .slice(0, 2)
      .map((match) => <ScheduleItem key={match.date} match={match} />)
  }

  const nextMonthPreviewComponents =
    isNextMatchLastInMonth && getNextMonthComponents()

  const monthMatchComponents = monthMatches.map((match) => (
    <ScheduleItem
      key={match.date}
      match={match}
      isNextMatch={nextMatch.matchDate?.toISOString() === match.date}
    />
  ))

  return (
    <>
      {monthMatchComponents}
      {isNextMatchLastInMonth &&
        !!(nextMonthPreviewComponents as JSX.Element[]).length && (
          <>
            <Divider
              variant="middle"
              style={{ marginTop: '16px', marginBottom: '16px' }}
            />
            {nextMonthPreviewComponents}
          </>
        )}
    </>
  )
}

export default MonthScheduleItems
