import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import GeneralBackground from './common/general-background'

const AtlBgTwo = () => {
  const { allFile } = useStaticQuery(query)
  const atlFluidData = allFile.nodes[0].childImageSharp?.fluid

  return <Background fluidData={atlFluidData as any} />
}

export default AtlBgTwo

const query = graphql`
  query AtlBgLogo {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "logos/ATLANTA UNITED FC.png" }
      }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 1, traceSVG: { color: "#A19060" }) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`
const Background = styled(GeneralBackground)`
  opacity: 0.23;
`
