import React from 'react'
import styled from 'styled-components'
import ToggleButton from '@material-ui/lab/ToggleButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { HomeAwayFilter } from 'src/pages/schedule'
import { SetState } from 'src/common/types'
import { glassyDefaultBG, Card, spacing } from 'src/common/styles'
import ScheduleFilters from './schedule-filters'
import useTheme from 'src/common/hooks/use-theme'

export type ScheduleTopBarProps = {
  clubFilter: string | null
  setClubFilter: SetState<string | null>
  homeAwayFilter: HomeAwayFilter
  setHomeAwayFilter: SetState<HomeAwayFilter>
  isFilterOpen: boolean
  setIsFilterOpen: SetState<boolean>
  children?: React.ReactNode
}
const ScheduleTopBar = ({
  clubFilter,
  setClubFilter,
  homeAwayFilter,
  setHomeAwayFilter,
  isFilterOpen,
  setIsFilterOpen,
  children,
}: ScheduleTopBarProps) => {
  const theme = useTheme()
  const triggered = useScrollTrigger({ threshold: 0, disableHysteresis: true })

  const handleFilterToggleChange = () => {
    setIsFilterOpen((isFilterOpen) => {
      isFilterOpen && setClubFilter(null)
      return !isFilterOpen
    })
  }

  return (
    <TabBar
      variant="outlined"
      //elevation={triggered ? 8 : 0}
      style={{ boxShadow: triggered ? theme.shadows[8] : 'none' }}
    >
      <BarContentWrapper>
        <FilterToggle
          value="filter"
          selected={isFilterOpen}
          onChange={handleFilterToggleChange}
          // size="small"
          disableRipple
        >
          <FilterListIcon />
        </FilterToggle>

        <TransformWrapper
          style={{
            transform: isFilterOpen ? 'rotateX(90deg)' : 'rotateX(0deg)',
          }}
        >
          {children}
          <ScheduleFilters
            clubFilter={clubFilter}
            setClubFilter={setClubFilter}
            homeAwayFilter={homeAwayFilter}
            setHomeAwayFilter={setHomeAwayFilter}
          />
        </TransformWrapper>
      </BarContentWrapper>
    </TabBar>
  )
}

export default ScheduleTopBar

export const topBarHeightPx = 48
// const TabBar = styled(Paper)`
const TabBar = styled(Card)`
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  /* margin: auto; */
  overflow: hidden;
  /* max-width: ${(p) => p.theme.breakpoints.values.md - 100}px; */
  & {
    padding: 0;
  }
  ${glassyDefaultBG}
  ${(p) => p.theme.breakpoints.up('md')} {
    box-shadow: unset;
    margin: 0;
    margin-top: ${spacing(2)};
  }
  .MuiTabs-root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin-left: 4px;
    transform: rotateX(0deg) translateZ(24px);
  }
  .MuiTabs-scroller {
    flex: unset;
  }
  .MuiTab-textColorInherit {
    opacity: 0.5;
  }
  .MuiTab-textColorInherit.Mui-selected {
    color: ${(p) => p.theme.palette.primary.main};
    opacity: 1;
  }
`
const BarContentWrapper = styled.div`
  margin: auto;
  width: 100%;
  /* max-width: ${(p) => p.theme.breakpoints.values.md - 100}px; */
  display: flex;
  justify-content: space-between;
`
const TransformWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  transform-style: preserve-3d;
  transition: ${(p) =>
    p.theme.transitions.create('transform', {
      duration: p.theme.transitions.duration.shorter,
    })};
`
const FilterToggle = styled(ToggleButton)`
  margin-right: 4px;
  border: none;
  border-right: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0;
`
