import React from 'react'
import useMatchData from 'src/common/hooks/use-match-data'
import useNextMatchData from 'src/common/hooks/use-next-match-data'
import ScheduleItem from './schedule-item'

type ClubFilterScheduleProps = {
  clubFilter: string | null
}
const ClubFilterSchedule = ({ clubFilter }: ClubFilterScheduleProps) => {
  const matches = useMatchData()
  const nextMatch = useNextMatchData()

  const clubFilterMatchComponents = matches
    .filter((match) => match.opponent === clubFilter)
    .map((match) => (
      <ScheduleItem
        key={match.date}
        match={match}
        isNextMatch={nextMatch.matchDate?.toISOString() === match.date}
      />
    ))

  //the fragments stop typescript from complaining
  return <>{clubFilterMatchComponents}</>
}

export default ClubFilterSchedule
