import React from 'react'
import styled from 'styled-components'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import HomeIcon from '@material-ui/icons/Home'
import FlightIcon from '@material-ui/icons/Flight'
import { SetState } from 'src/common/types'
import { HomeAwayFilter } from 'src/pages/schedule'
import useMatchData from 'src/common/hooks/use-match-data'

type ScheduleFiltersProps = {
  clubFilter: string | null
  setClubFilter: SetState<string | null>
  homeAwayFilter: HomeAwayFilter
  setHomeAwayFilter: SetState<HomeAwayFilter>
}
const ScheduleFilters = ({
  clubFilter,
  setClubFilter,
  homeAwayFilter,
  setHomeAwayFilter,
}: ScheduleFiltersProps) => {
  const matches = useMatchData()

  const handleAutocompleteChange = (e: any, value: string | null) => {
    setClubFilter(value)
  }

  const handleHomeAwayChange = (e: any, value: HomeAwayFilter) => {
    setHomeAwayFilter(value)
  }

  const teamNames = Array.from(
    new Set(matches.map((match) => match.opponent))
  ).sort()

  return (
    <FilterDiv>
      <ClubFilterWrapper>
        <Autocomplete
          value={clubFilter}
          onChange={handleAutocompleteChange}
          options={teamNames}
          size="small"
          autoComplete
          autoSelect
          // autoHighlight
          blurOnSelect
          openOnFocus
          renderInput={(params) => (
            <TextField {...params} placeholder="opponent" variant="outlined" />
          )}
        />
      </ClubFilterWrapper>
      <ToggleButtonGroup
        size="small"
        value={homeAwayFilter}
        onChange={handleHomeAwayChange}
        exclusive
      >
        <ToggleButton value="H" disableRipple disabled={!!clubFilter}>
          <HomeIcon />
        </ToggleButton>
        <ToggleButton value="A" disableRipple disabled={!!clubFilter}>
          <FlightIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </FilterDiv>
  )
}

export default ScheduleFilters

const FilterDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 48px;
  padding: 4px;
  padding-left: 0;
  transform: rotateX(-90deg) translateZ(24px);
`
const ClubFilterWrapper = styled.div`
  max-width: 330px;
  flex-grow: 1;
  margin-right: 4px;
`
