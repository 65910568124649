import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

type GeneralBackgroundProps = {
  fluidData: any
  className?: string
}
const GeneralBackground = ({
  fluidData,
  className,
}: GeneralBackgroundProps) => {
  const props = useMotionNavOffsetStyle({}, { dodge: 'none' })
  return (
    <Wrapper {...props} className={className}>
      <BgImage Tag="div" fluid={fluidData} />
    </Wrapper>
  )
}

export default GeneralBackground

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -9999;
`
const BgImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`
